
import React, {useEffect, useState} from 'react'

import io from "socket.io-client"

import TextContainer from "../TextContainer/TextContainer";
import Messages from "../Messages/Messages";
import InfoBar from "../InfoBar/InfoBar";
import Input from "../Input/Input";
import './Chat.css'
// eslint-disable-next-line
const ENDPOINT = 'https://chat.mym666.fun/'
let socket;
const Chat = ({ location }) => {
    const [name, setName] = useState('')
    const [room, setRoom] = useState('')
    const [users, setUsers] = useState('')
    const [message, setMessage] = useState('')
    const [messages, setMessages] = useState([])
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const name = params.get('name');
        const room = params.get('room');
        // 配置 withCredentials
        const options = {
            withCredentials: false
        };
        socket = io(ENDPOINT, options)
        setRoom(room)
        setName(name)

        socket.emit('join', { name, room }, (error) => {
            if (error) {
                alert(error)
            }
        });
    }, [])

    useEffect(() => {
        socket.on('message', message => {
            setMessages(messages => [...messages, message ])
        });

        socket.on("roomData", ({ users }) => {
            setUsers(users)
        });
    }, [])

    const sendMessage = (event) => {
        // 阻止表单的默认提交行为
        event.preventDefault();

        if (message) {
            socket.emit('sendMessage', message, () => setMessage(''));
        }
    }


    return (
        <div className="outerContainer">
            <div className="container">
                <InfoBar room={room} />
                <Messages messages={messages} name={name} />
                <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
            </div>
            <TextContainer users={users} />
        </div>
    )
}
export default Chat

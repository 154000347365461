import React from 'react'

import Join from './components/Join/Join'

import { BrowserRouter as Router, Route } from "react-router-dom"
import Chat from "./components/chat/Chat";
import Input from "./components/Input/Input";
import InfoBar from "./components/InfoBar/InfoBar";

const App = () => {
    return (
        <Router>
            <Route path="/" exact component={Join} />
            <Route path="/chat" component={Chat} />
            <Route path="/input" component={Input} />
            <Route path="/infobar" component={InfoBar} />
        </Router>
    )
}
export default App
